import '../style/components-style/NetZeroBottomTool.css'
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import config from "../config";
import {FiDownload} from "react-icons/fi";
import {saveAs} from 'file-saver';
import {
    AlignmentType,
    Document,
    HeadingLevel,
    ImageRun,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    WidthType
} from "docx";
import {Icon} from "@iconify/react";
import Grid from "../environment-page-components/Grid";
import {useAuth} from "../authentication/AuthProvider";
import {toPng} from "html-to-image";
import Scope12ShortTermTool from "./Scope1&2ShortTermTool";
import Scope3ShortTermTool from "./Scope3ShortTermTool";

function NetZeroBottomShortTermTool({selectedText, selectedRow}) {
    const [shortTermToolVersion, setShortTermToolVersion] = useState("scope1&2");
    const [isOpen, setIsOpen] = useState(true);

    const [targetSettingMethod, setTargetSettingMethod] = useState("Absolute Contraction Approach");
    const [baseYear, setBaseYear] = useState("");
    const [targetYear, setTargetYear] = useState("");
    const [mostRecentYear, setMostRecentYear] = useState("");
    const [actionYear, setActionYear] = useState("");

    const [scope1Emission, setScope1Emission] = useState(0.0);
    const [scope2Emission, setScope2Emission] = useState(0.0);
    const [scope3Emission, setScope3Emission] = useState(0.0);
    const [MRYScope1Emission, setMRYScope1Emission] = useState(0.0);
    const [MRYScope2Emission, setMRYScope2Emission] = useState(0.0);
    const [yearsList, setYearsList] = useState([]);
    const [scope1EmissionsList, setScope1EmissionsList] = useState([]);
    const [scope2EmissionsList, setScope2EmissionsList] = useState([]);
    const [scope1TargetEmission, setScope1TargetEmission] = useState(0.0);
    const [scope2TargetEmission, setScope2TargetEmission] = useState(0.0);

    const [rowData, setRowData] = useState([]);
    const gridApiRef = useRef(null);

    const [baseYearOutput, setBaseYearOutput] = useState(0);
    const [targetYearOutput, setTargetYearOutput] = useState(0);
    const [economicIntensityBase, setEconomicIntensityBase] = useState(NaN);
    const [economicIntensityTarget, setEconomicIntensityTarget] = useState(NaN);
    const [economicIntensityReduction, setEconomicIntensityReduction] = useState(NaN);
    const [cementTargetPhysicalIntensity, setCementTargetPhysicalIntensity] = useState(NaN);

    const {user} = useAuth();
    const userId = user ? user.username : null;

    const shortTermGraphRef = useRef(null);

    const baseYearList = Array.from({length: 10}, (_, i) => (2015 + i).toString());
    const targetYearList = Array.from({length: 8}, (_, i) => (2028 + i).toString());
    const mostRecentYearList = Array.from({length: 6}, (_, i) => (2020 + i).toString());

    const actionYearList = useMemo(() => {
        if (!baseYear || !targetYear) return []; // Return empty list if years are not set

        const start = parseInt(baseYear, 10);
        const end = parseInt(targetYear, 10);

        if (isNaN(start) || isNaN(end) || start >= end) return []; // Ensure valid years

        return Array.from({length: end - start}, (_, i) => start + i);
    }, [baseYear, targetYear]);

    const onGridReady = useCallback((params) => {
        gridApiRef.current = params.api;
    }, []);

    const resetForm = () => {
        setBaseYear("");
        setTargetYear("");
        setMostRecentYear("");
        setMRYScope1Emission(0.0);
        setMRYScope2Emission(0.0);
    };

    // const computeFLA = (scope) => {
    //     const startYear = parseInt(baseYear, 10);
    //     const endYear = parseInt(targetYear, 10);
    //     const midYear = parseInt(mostRecentYear, 10);
    //     let reduction = 0;
    //     if (scope === 'scope1') {
    //         reduction = 1 - (MRYScope1Emission / scope1TargetEmission)
    //     } else if (scope === 'scope2') {
    //         reduction = 1 - (MRYScope2Emission / scope2TargetEmission)
    //     }
    //     if (reduction >= 0.9) {
    //         return 'Maint. target'
    //     } else if (reduction < 0) {
    //         return 'Not Required'
    //     } else {
    //         const fla = reduction + (((endYear - midYear) / (2050 - midYear)) * (0.9 - reduction)) - (endYear - startYear) * 0.042
    //         return fla > 0 ? fla : 'Not Required';
    //     }
    // }

    useEffect(() => {
        if (!selectedRow || !selectedRow.id) {
            setRowData([]);
            return;
        }

        loadShortTermToolInputs();

        fetch(`${config.apiUrl}/api/get-emission-values-by-scope?row_id=${selectedRow.id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch emissions data");
                }
                return response.json();
            })
            .then((data) => {
                setScope1Emission(data.scope1 / 1000 || 0.0);
                setScope2Emission(data.scope2 / 1000 || 0.0);
                setScope3Emission(data.scope3 / 1000 || 0.0);
            })
            .catch((error) => {
                console.error("Error fetching emissions data:", error);
            });

        fetchData()
    }, [selectedRow]);

    useEffect(() => {
        const startYear = parseInt(baseYear, 10);
        const endYear = parseInt(targetYear, 10);

        if (isNaN(startYear) || isNaN(endYear) || startYear >= endYear) {
            setScope1EmissionsList([]);
            setScope2EmissionsList([]);
            return;
        }

        const numYears = endYear - startYear + 1;
        const years = Array.from({length: numYears}, (_, i) => startYear + i);

        const generateEmissions = (baseEmission) => {
            return Array.from({length: numYears}, (_, i) =>
                baseEmission * (1 - 0.042 * i)
            );
        };

        const scope1Emissions = generateEmissions(scope1Emission);
        const scope2Emissions = generateEmissions(scope2Emission);

        setScope1EmissionsList(scope1Emissions);
        setScope2EmissionsList(scope2Emissions);
        setScope1TargetEmission(scope1Emissions.at(-1));
        setScope2TargetEmission(scope2Emissions.at(-1));
        setYearsList(years);
    }, [baseYear, targetYear, scope1Emission, scope2Emission]);

    const toggleContainer = () => setIsOpen(!isOpen);

    const fetchShortTermToolInputs = async (templateId) => {
        try {
            const response = await fetch(`${config.apiUrl}/fetch_data`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    table_name: "short_term_tool_inputs",
                    condition: `template_id = '${templateId}'`,
                }),
            });
            const inputData = await response.json();

            if (!inputData || inputData.length === 0) {
                await addShortTermToolInputs(templateId);
            }

            return inputData;
        } catch (error) {
            console.error("Error fetching short-term tool inputs:", error);
            return null;
        }
    };

    const loadShortTermToolInputs = async () => {
        if (!selectedRow || !selectedRow.id) {
            resetForm();
            return;
        }

        const shortTermToolInputsData = await fetchShortTermToolInputs(selectedRow.id);

        if (shortTermToolInputsData && shortTermToolInputsData.length > 0) {
            const data = shortTermToolInputsData[0];

            setBaseYear(data.base_year || '');
            setTargetYear(data.target_year || '');
            setTargetSettingMethod(data.target_setting_method || '');
            setMostRecentYear(data.most_recent_year || '');
            setMRYScope1Emission(data.mry_scope1 || 0.0);
            setMRYScope2Emission(data.mry_scope2 || 0.0);
            setBaseYearOutput(data.base_year_output || 0.0);
            setTargetYearOutput(data.target_year_output || 0.0);
        } else {
            resetForm();
        }
    };

    const addShortTermToolInputs = async (templateId) => {
        try {
            const apiUrl = `${config.apiUrl}/add_data`;
            const body = JSON.stringify({
                table_name: "short_term_tool_inputs",
                data: {
                    template_id: templateId,
                    user_id: userId,
                    base_year: null,
                    target_year: null,
                    target_setting_method: null,
                    most_recent_year: null,
                    mry_scope1: null,
                    mry_scope2: null,
                    base_year_output: null,
                    target_year_output: null
                },
            });

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: body,
            });

            const result = await response.json();
            if (result.status !== "success") {
                console.error("Failed to add new short term tool inputs:", result.message);
            }
        } catch (error) {
            console.error("Error adding new short term tool inputs:", error);
        }
    };

    const saveShortTermToolInputs = async () => {
        if (selectedRow && userId) {
            try {
                const apiUrl = `${config.apiUrl}/update_data`;
                const body = JSON.stringify({
                    table_name: "short_term_tool_inputs",
                    data: {
                        template_id: selectedRow.id,
                        base_year: baseYear,
                        target_year: targetYear,
                        target_setting_method: targetSettingMethod,
                        most_recent_year: mostRecentYear,
                        mry_scope1: MRYScope1Emission,
                        mry_scope2: MRYScope2Emission,
                        base_year_output: baseYearOutput,
                        target_year_output: targetYearOutput
                    },
                    condition: `template_id = '${selectedRow.id}'`,
                });

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: body,
                });

                const result = await response.json();

                if (result.status === "success") {
                    alert("Short-term tool inputs updated successfully!");
                    loadShortTermToolInputs();
                } else {
                    alert("Failed to save short-term tool inputs.");
                }
            } catch (error) {
                console.error("Error updating short-term tool inputs:", error);
                alert("Error saving short-term tool inputs.");
            }
        } else {
            alert("User ID is null. Cannot update short-term tool inputs.");
        }
    }

    const fetchData = () => {
        if (!userId) return;

        const condition = `user_id = '${userId}' AND template_id = '${selectedRow.id}' AND tool_type = 'short-term'`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                table_name: "net_zero_tool_actions",
                attribute: "*",
                condition: condition,
                include_blobs: true,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                setRowData(data)
                if (gridApiRef.current) {
                    gridApiRef.current.setRowData(data || []);
                }
            })
            .catch((error) => console.error("Error fetching data:", error));
    }

    const addRow = () => {
        if (!gridApiRef.current) return;

        if (actionYear === "") {
            window.alert("Select the year of the newly added action!");
            return;
        }

        const newRow = {
            user_id: userId,
            template_id: selectedRow.id,
            tool_type: "short-term",
            action: "",
            category: "",
            year: parseInt(actionYear, 10),
            details: "",
            estimated_ghg: 0,
        };

        fetch(`${config.apiUrl}/add_data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                table_name: "net_zero_tool_actions",
                data: newRow,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    gridApiRef.current.applyTransaction({add: [newRow]});
                    fetchData();
                }
            })
            .catch((error) => console.error("Error adding data:", error));
    };

    const deleteSelectedRows = () => {
        if (!gridApiRef.current) return;

        const selectedData = gridApiRef.current.getSelectedRows();

        selectedData.forEach((row) => {
            fetch(`${config.apiUrl}/delete_data`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({table_name: "net_zero_tool_actions", id: row.id}),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        gridApiRef.current.applyTransaction({remove: [row]});
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error deleting data:", error));
        });
    };

    const saveTable = () => {
        if (!gridApiRef.current) return;
        const allRows = gridApiRef.current.getRenderedNodes ? (gridApiRef.current.getRenderedNodes() || []).map((node) => node.data) : [];

        allRows.forEach((row) => {
            fetch(`${config.apiUrl}/update_data`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    table_name: "net_zero_tool_actions",
                    data: row,
                    condition: `id = '${row.id}'`,
                }),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error updating data:", error));
        });

        // setDisableSave(true);
    };

    const columnDefs = [
        {field: "id", hide: true},
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerName: "Sl no.",
            field: "sno",
            width: 80,
            pinned: "left",
            valueGetter: (params) => params.node.rowIndex + 1,
            sortable: false,
            filter: false,
        },
        {
            headerName: "Action",
            field: "action",
            flex: 1,
            editable: true,
            cellEditor: "agTextCellEditor",
        },
        {
            headerName: "Term",
            field: "tool_type",
            flex: 1,
            editable: false,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Long-term", "Short-term", "Freight"],
            },
        },
        {
            headerName: "Category",
            field: "category",
            flex: 1,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Operational", "Strategic", "Tactical"],
            },
        },
        {
            headerName: "Year",
            field: "year",
            flex: 1,
            editable: false,
        },
        {
            headerName: "Details",
            field: "details",
            flex: 4,
            editable: true,
            cellEditor: "agTextCellEditor",
        },
        {
            headerName: "ESTIMATED GHG REDUCTIONS (ton CO₂e)",
            field: "estimated_ghg",
            flex: 1,
            editable: true,
            cellStyle: {textAlign: "right"},
            cellEditor: 'agTextCellEditor',
            cellEditorParams: {
                useFormatter: true,
                inputType: 'number',
            }
        }
    ];

    const freightToolGridProps = {
        columnDefs: columnDefs,
        rowData: rowData,
        rowSelection: "multiple",
        onGridReady: onGridReady,
        domLayout: "autoHeight",
    };

    const shortTermGraphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "linear",
                title: {display: true, text: "Year"},
                grid: {display: false},
                ticks: {
                    stepSize: 1,
                },
            },
            y: {
                type: "linear",
                position: "left",
                title: {display: true, text: "tCO₂ eq"},
                grid: {color: "rgba(200, 200, 200, 0.3)"},
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                offset: 1,
                color: 'black',
                formatter: (value) => {
                    if (typeof value === "object" && value !== null && "y" in value) {
                        return value.y.toFixed(2);
                    }
                    if (typeof value === "number" && !isNaN(value)) {
                        return value.toFixed(2);
                    }
                    return value;
                },
            },
        },
    };

    const shortTermGraphData = {
        labels: yearsList,  // x-axis labels (years from baseYear to targetYear)
        datasets: [
            {
                label: `Scope 1 Emissions`,
                data: scope1EmissionsList,  // y-axis values for Scope 1
                borderColor: "rgba(255, 193, 7, 1)",  // Yellow
                backgroundColor: "rgba(255, 193, 7, 0.2)",  // Light yellow fill
                tension: 0.3,
                yAxisID: "y",
            },
            {
                label: `Scope 2 Emissions`,
                data: scope2EmissionsList,  // y-axis values for Scope 2
                borderColor: "rgba(33, 150, 243, 1)",  // Blue
                backgroundColor: "rgba(33, 150, 243, 0.2)",  // Light blue fill
                tension: 0.3,
                yAxisID: "y",
            },
            {
                label: "Most Recent Year Scope 1 Emission",
                data: [{x: mostRecentYear, y: MRYScope1Emission}],
                backgroundColor: "rgba(255, 193, 7, 1)",  // Yellow dot
                borderColor: "rgba(255, 193, 7, 1)",
                pointRadius: 8,
                pointHoverRadius: 10,
                showLine: false,
                datalabels: {  // Disable labels for this dataset
                    display: false
                },
                pointStyle: 'rect',
            },
            {
                label: "Most Recent Year Scope 2 Emission",
                data: [{x: mostRecentYear, y: MRYScope2Emission}],
                backgroundColor: "rgba(33, 150, 243, 1)",  // Blue dot
                borderColor: "rgba(33, 150, 243, 1)",
                pointRadius: 8,
                pointHoverRadius: 10,
                showLine: false,
                datalabels: {  // Disable labels for this dataset
                    display: false
                },
                pointStyle: 'rect',
            }
        ],
    };

    const generateTable = (data) => {
        console.log(data)
        return new Table({
            columnWidths: [4000, 6000],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: data.map(row => new TableRow({
                children: row.map(cell => new TableCell({
                    children: [new Paragraph({text: cell, spacing: {after: 100}})],
                    width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                    },
                }))
            }))
        });
    };

    const shortTermToolExport = async (shortTermGraphRef) => {
        try {
            const shortTermGraphUrl = await toPng(shortTermGraphRef.current, {quality: 1});

            const doc = new Document({
                sections: [
                    {
                        properties: {},
                        children: [
                            // Main Title (Bold, Centered, Large)
                            new Paragraph({
                                text: "Net Zero Short-Term Scope 1&2 & Scope 3 Tool Report",
                                heading: HeadingLevel.TITLE,
                                alignment: AlignmentType.CENTER,
                                spacing: {after: 300},
                            }),

                            // Section: Scope 1 & 2 Input Data
                            new Paragraph({
                                text: "Input Data (Scope 1 & 2)",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 200},
                            }),

                            generateTable([
                                ["Target Setting Method", "Absolute Contraction Approach"],
                                ["Base Year", String(baseYear) || "N/A"],
                                ["Base Year | Scope 1 emissions (tCO₂e)", scope1Emission.toFixed(2) + " tCO₂e"],
                                ["Base Year | Scope 2 emissions (tCO₂e)", scope2Emission.toFixed(2) + " tCO₂e"],
                                ["Target Year", String(targetYear) || "N/A"],
                                ["Most Recent Year", String(mostRecentYear) || "N/A"],
                                ["MRY | Scope 1 emissions (tCO₂e)", String(MRYScope1Emission) || "0 tCO₂e"],
                                ["MRY | Scope 2 emissions (tCO₂e)", String(MRYScope2Emission) || "0 tCO₂e"],
                            ]),

                            new Paragraph({
                                text: "Cross-Sector Absolute Reduction / Absolute Contraction Approach (ACA) (Scope 1 & 2)",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 200},
                            }),

                            generateTable([
                                ["", "Base Year", "Most Recent Year", "Target Year", "% Reduction to Date", "% SBT Reduction"],
                                ["Scope 1 emissions (tCO2e)", scope1Emission.toFixed(2), MRYScope1Emission.toFixed(2), scope1TargetEmission.toFixed(2), `${((1 - (MRYScope1Emission / scope1Emission)) * 100).toFixed(2)}%`, `${(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%`],
                                ["Scope 2 emissions (tCO2e)", scope2Emission.toFixed(2), MRYScope2Emission.toFixed(2), scope2TargetEmission.toFixed(2), `${((1 - (MRYScope2Emission / scope2Emission)) * 100).toFixed(2)}%`, `${(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%`],
                                ["Scope 1 + 2 emissions (tCO2e)", (scope1Emission + scope2Emission).toFixed(2), (MRYScope1Emission + MRYScope2Emission).toFixed(2), (scope1TargetEmission + scope2TargetEmission).toFixed(2), `${((1 - ((MRYScope1Emission + MRYScope2Emission) / (scope1Emission + scope2Emission))) * 100).toFixed(2)}%`, `${(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%`],
                            ]),

                            // Section: Scope 3 Input Data
                            new Paragraph({
                                text: "Input Data (Scope 3)",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 200},
                            }),

                            generateTable([
                                ["Target Setting Method", targetSettingMethod],
                                ["Base Year", String(baseYear) || "N/A"],
                                ["Base Year | Scope 3 emissions (tCO₂e)", scope3Emission.toFixed(2) + " tCO₂e"],
                                ["Target Year", String(targetYear) || "N/A"],
                                ...((targetSettingMethod === 'Economic intensity' || targetSettingMethod === 'Physical intensity' || targetSettingMethod === 'Cement SDA 1.5ºC') ? [
                                    ["Base year output", String(baseYearOutput) || "N/A"],
                                ] : []),
                                ...((targetSettingMethod === 'Physical intensity' || targetSettingMethod === 'Cement SDA 1.5ºC') ? [
                                    ["Target year output", String(targetYearOutput) || "N/A"],
                                ] : [])
                            ]),

                            new Paragraph({
                                text: "Cross-Sector Absolute Reduction / Absolute Contraction Approach (ACA) (Scope 3)",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 200},
                            }),

                            generateTable([
                                ["", `Base year (${baseYear})`, `Target year (${targetYear})`, "% SBT Reduction"],
                                ...((targetSettingMethod === 'Absolute Contraction Approach') ? [
                                    ["Absolute emissions - WB2C (tCO2e)", scope3Emission.toFixed(2), (scope3Emission * (1 - 0.025 * (yearsList.length - 1))).toFixed(2), `${(((yearsList.length - 1) * 0.025) * 100).toFixed(2)}%`],
                                    ["Absolute emissions - 1.5C (tCO2e)", scope3Emission.toFixed(2), (scope3Emission * (1 - 0.042 * (yearsList.length - 1))).toFixed(2), `${(((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%`],
                                ] : []),
                                ...((targetSettingMethod === 'Economic intensity') ? [
                                    ["Economic intensity (tCO2/unit value added)", economicIntensityBase.toFixed(2), economicIntensityTarget.toFixed(2), `${(economicIntensityReduction * 100).toFixed(2)}%`],
                                ] : []),
                                ...((targetSettingMethod === 'Physical intensity') ? [
                                    ["Physical intensity (tCO2/custom physical unit)", economicIntensityBase.toFixed(2), economicIntensityTarget.toFixed(2), `${(economicIntensityReduction * 100).toFixed(2)}%`],
                                ] : []),
                                ...((targetSettingMethod === 'Cement SDA 1.5ºC') ? [
                                    ["Physical intensity (tCO2e/t cement)", economicIntensityBase.toFixed(2), cementTargetPhysicalIntensity.toFixed(2), `${((economicIntensityBase - cementTargetPhysicalIntensity) / economicIntensityBase).toFixed(2)}%`],
                                ] : []),
                            ]),

                            new Paragraph({
                                text: "Actions Table",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 200},
                            }),

                            generateTable([
                                ["Sl no.", "Action", "Term", "Category", "Year", "Details", "ESTIMATED GHG REDUCTIONS (ton CO₂e)"],
                                ...rowData.map((action, index) => [
                                    String(index + 1),
                                    action.action || "N/A",
                                    action.tool_type || "N/A",
                                    action.category || "N/A",
                                    String(action.year) || "N/A",
                                    action.details || "N/A",
                                    String(action.estimated_ghg) || "N/A"
                                ])
                            ]),

                            // Section: Graphs
                            new Paragraph({
                                text: "Short Term Emissions Graph",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 100},
                            }),

                            shortTermGraphUrl ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: shortTermGraphUrl,
                                        transformation: {width: 650, height: 300},
                                    }),
                                ],
                            }) : new Paragraph({text: 'No short term emissions graph available.'}),
                        ],
                    },
                ],
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, "Short_Term_Tool_Report.docx");
        } catch (error) {
            console.error("Error generating DOCX:", error);
            alert("An error occurred while generating the DOCX file.");
        }
    };

    return (
        <div className="absolute-target-section">
            <div className="net-zero-tool-header">
                <h2
                    onClick={toggleContainer}
                    onMouseEnter={() => document.querySelector('.net-zero-tool-header h2').style.color = 'grey'}
                    onMouseLeave={() => document.querySelector('.net-zero-tool-header h2').style.color = 'black'}
                    style={{cursor: 'pointer'}}
                >
                    {selectedText.netZeroShortTermToolTitle || 'Net Zero Short Term Tool'}
                </h2>
            </div>
            {isOpen && (<div className="tool-section">
                <div className="tool-version-tabs">
                    <button
                        className={`tool-version-tab ${shortTermToolVersion === 'scope1&2' ? 'active' : ''}`}
                        onClick={() => setShortTermToolVersion('scope1&2')}
                    >
                        Scope 1 & 2
                    </button>
                    <button
                        className={`tool-version-tab ${shortTermToolVersion === 'scope3' ? 'active' : ''}`}
                        onClick={() => setShortTermToolVersion('scope3')}
                    >
                        Scope 3
                    </button>
                </div>
                {(shortTermToolVersion === 'scope1&2' ?
                    <Scope12ShortTermTool
                        resetForm={resetForm}
                        saveShortTermToolInputs={saveShortTermToolInputs}
                        baseYear={baseYear}
                        setBaseYear={setBaseYear}
                        baseYearList={baseYearList}
                        targetYear={targetYear}
                        setTargetYear={setTargetYear}
                        targetYearList={targetYearList}
                        mostRecentYear={mostRecentYear}
                        setMostRecentYear={setMostRecentYear}
                        mostRecentYearList={mostRecentYearList}
                        scope1Emission={scope1Emission}
                        scope2Emission={scope2Emission}
                        MRYScope1Emission={MRYScope1Emission}
                        setMRYScope1Emission={setMRYScope1Emission}
                        MRYScope2Emission={MRYScope2Emission}
                        setMRYScope2Emission={setMRYScope2Emission}
                        scope1TargetEmission={scope1TargetEmission}
                        scope2TargetEmission={scope2TargetEmission}
                        yearsList={yearsList}
                        shortTermGraphRef={shortTermGraphRef}
                        shortTermGraphData={shortTermGraphData}
                        shortTermGraphOptions={shortTermGraphOptions}
                    /> : <Scope3ShortTermTool
                        resetForm={resetForm}
                        saveShortTermToolInputs={saveShortTermToolInputs}
                        targetSettingMethod={targetSettingMethod}
                        setTargetSettingMethod={setTargetSettingMethod}
                        baseYear={baseYear}
                        setBaseYear={setBaseYear}
                        baseYearList={baseYearList}
                        targetYear={targetYear}
                        setTargetYear={setTargetYear}
                        targetYearList={targetYearList}
                        scope3Emission={scope3Emission}
                        yearsList={yearsList}
                        baseYearOutput={baseYearOutput}
                        setBaseYearOutput={setBaseYearOutput}
                        targetYearOutput={targetYearOutput}
                        setTargetYearOutput={setTargetYearOutput}
                        economicIntensityBase={economicIntensityBase}
                        setEconomicIntensityBase={setEconomicIntensityBase}
                        economicIntensityTarget={economicIntensityTarget}
                        setEconomicIntensityTarget={setEconomicIntensityTarget}
                        economicIntensityReduction={economicIntensityReduction}
                        setEconomicIntensityReduction={setEconomicIntensityReduction}
                        cementTargetPhysicalIntensity={cementTargetPhysicalIntensity}
                        setCementTargetPhysicalIntensity={setCementTargetPhysicalIntensity}
                    />)}

                <div className="section">
                    <div className="data-table">
                        <div>
                            <div style={{marginBottom: "10px", display: "flex", gap: "10px"}}>
                                <button onClick={addRow} style={{
                                    padding: "8px 12px",
                                    background: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="ic:round-plus" style={{fontSize: "16px"}}/>
                                </button>
                                <button onClick={deleteSelectedRows} style={{
                                    padding: "8px 12px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="ic:round-minus" style={{fontSize: "16px"}}/>
                                </button>
                                <button onClick={saveTable} style={{
                                    padding: "8px 12px",
                                    background: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="mdi:floppy-disk" style={{fontSize: "16px"}}/>
                                </button>
                                <select
                                    className="action-year-select"
                                    value={actionYear}
                                    onChange={(e) => setActionYear(e.target.value)}
                                >
                                    <option value="">Select Action Year</option>
                                    {actionYearList.map((year, index) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                                <span className="action-year-remark">
                                    * Select action year between base year and target year
                                </span>
                            </div>
                            <Grid {...freightToolGridProps} />
                        </div>
                    </div>
                </div>
                <div className="net-zero-report-container">
                    <button
                        onClick={() => shortTermToolExport(shortTermGraphRef)}
                        className="generate-report-supplier-button">
                        <FiDownload size={18} style={{marginRight: '8px'}}/>
                        {`${selectedText.generateNetZeroReport} (Corporate Short-term Tool)`}
                    </button>
                </div>
            </div>)}
        </div>
    );
}

export default NetZeroBottomShortTermTool;
import '../style/components-style/NetZeroBottomTool.css'
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import config from "../config";
import {FiDownload} from "react-icons/fi";
import {saveAs} from 'file-saver';
import {AlignmentType, Document, HeadingLevel, Packer, Paragraph, Table, TableCell, TableRow, WidthType} from "docx";
import {Icon} from "@iconify/react";
import Grid from "../environment-page-components/Grid";
import {useAuth} from "../authentication/AuthProvider";

const sectorPathwayList = ["Cross-sector pathway",
    "FLAG (Agriculture only)",
    "Iron and steel",
    "Cement",
    "Services - Buildings",
    "Residential - Buildings"];

const percentageReductionData = {
    "Cross-sector pathway": {S1AbsTarget: 0.9, S12AbsTarget: 0.9},
    "FLAG (Agriculture only)": {S1AbsTarget: 0.72, S12AbsTarget: 0.72},
    "Iron and steel": {S1AbsTarget: 0.9122, S12AbsTarget: 0.9299},
    "Cement": {S1AbsTarget: 0.946, S12AbsTarget: 0.9495},
    "Services - Buildings": {S1AbsTarget: 0.9857, S12AbsTarget: 0.9960},
    "Residential - Buildings": {S1AbsTarget: 0.9468, S12AbsTarget: 0.9789}
};

function NetZeroBottomLongTermTool({selectedText, selectedRow}) {
    const [isOpen, setIsOpen] = useState(true);
    const [baseYear, setBaseYear] = useState("");
    const [targetYear, setTargetYear] = useState("");
    const [actionYear, setActionYear] = useState("");

    const [sectorPathway, setSectorPathway] = useState("");
    const [scope1Emission, setScope1Emission] = useState(0.0);
    const [scope2Emission, setScope2Emission] = useState(0.0);
    const [scope3Emission, setScope3Emission] = useState(0.0);
    const [totalEmissions, setTotalEmissions] = useState(0.0);

    const [selectedTargetCoverage, setSelectedTargetCoverage] = useState('');

    const [baseYearIntensity, setBaseYearIntensity] = useState(0.0);
    const [targetYearIntensity, setTargetYearIntensity] = useState(0.0);
    const [percentageReduction, setPercentReduction] = useState(0);
    const [longTermSBTFormulation, setLongTermSBTFormulation] = useState("");

    const [rowData, setRowData] = useState([]);
    const gridApiRef = useRef(null);

    const {user} = useAuth();
    const userId = user ? user.username : null;

    const baseYearList = Array.from({length: 11}, (_, i) => (2015 + i).toString());
    const targetYearList = Array.from({length: 26}, (_, i) => (2025 + i).toString());

    const actionYearList = useMemo(() => {
        if (!baseYear || !targetYear) return []; // Return empty list if years are not set

        const start = parseInt(baseYear, 10);
        const end = parseInt(targetYear, 10);

        if (isNaN(start) || isNaN(end) || start >= end) return []; // Ensure valid years

        return Array.from({length: end - start}, (_, i) => start + i);
    }, [baseYear, targetYear]);

    const onGridReady = useCallback((params) => {
        gridApiRef.current = params.api;
    }, []);

    const resetForm = () => {
        setBaseYear("");
        setTargetYear("");
        setSectorPathway("");
        setSelectedTargetCoverage("");
    };

    useEffect(() => {
        if (!selectedRow || !selectedRow.id) {
            setRowData([]);
            return;
        }

        loadLongTermToolInputs();

        fetch(`${config.apiUrl}/api/get-emission-values-by-scope?row_id=${selectedRow.id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch emissions data");
                }
                return response.json();
            })
            .then((data) => {
                setScope1Emission(data.scope1 / 1000 || 0.0);
                setScope2Emission(data.scope2 / 1000 || 0.0);
                setScope3Emission(data.scope3 / 1000 || 0.0);
            })
            .catch((error) => {
                console.error("Error fetching emissions data:", error);
            });

        fetchData();
    }, [selectedRow]);

    useEffect(() => {
        const calculateTotalEmissions = () => {
            switch (selectedTargetCoverage) {
                case "Scope 1":
                    return scope1Emission;

                case "Scope 1 & 2":
                    return scope1Emission + scope2Emission;

                case "Scope 3":
                    return scope3Emission;

                case "Scope 1,2 & 3":
                    return scope1Emission + scope2Emission + scope3Emission;

                default:
                    return 0;
            }
        };

        const emissions = calculateTotalEmissions();
        setTotalEmissions(emissions);
    }, [selectedTargetCoverage, scope1Emission, scope2Emission, scope3Emission]);

    useEffect(() => {
        if (sectorPathway && percentageReductionData[sectorPathway]) {
            const reduction = selectedTargetCoverage === "Scope1" ? percentageReductionData[sectorPathway].S1AbsTarget : percentageReductionData[sectorPathway].S12AbsTarget;
            setPercentReduction(reduction);
        } else {
            setPercentReduction(0.0);
        }
    }, [sectorPathway, selectedTargetCoverage]);

    useEffect(() => {
        if (selectedRow) {
            const companyName = selectedRow.product_service ? selectedRow.product_service : (selectedRow.organization ? selectedRow.organization : selectedRow.corporate);
            if (sectorPathway === "FLAG (Agriculture only)") {
                const SBTFormulation = `${companyName} commits to reduce ${selectedTargetCoverage} emissions ${(percentageReduction * 100).toFixed(2)} by ${targetYear} from a ${baseYear} base year`;
                setLongTermSBTFormulation(SBTFormulation);
            } else {
                const SBTFormulation = `${companyName} commits to reduce emissions from agriculture, forestry, and land-use ${(percentageReduction * 100).toFixed(2)} by ${targetYear} from a ${baseYear} base year`;
                setLongTermSBTFormulation(SBTFormulation);
            }
        }
    }, [selectedRow, sectorPathway, selectedTargetCoverage, percentageReduction, targetYear, baseYear]);

    const toggleContainer = () => setIsOpen(!isOpen);

    const fetchLongTermToolInputs = async (templateId) => {
        try {
            const response = await fetch(`${config.apiUrl}/fetch_data`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    table_name: "long_term_tool_inputs",
                    condition: `template_id = '${templateId}'`,
                }),
            });
            const inputData = await response.json();

            if (!inputData || inputData.length === 0) {
                await addLongTermToolInputs(templateId);
            }

            return inputData;
        } catch (error) {
            console.error("Error fetching long-term tool inputs:", error);
            return null;
        }
    };

    const loadLongTermToolInputs = async () => {
        if (!selectedRow || !selectedRow.id) {
            resetForm();
            return;
        }

        const longTermToolInputsData = await fetchLongTermToolInputs(selectedRow.id);

        if (longTermToolInputsData && longTermToolInputsData.length > 0) {
            const data = longTermToolInputsData[0];

            setBaseYear(data.base_year || '');
            setTargetYear(data.target_year || '');
            setSectorPathway(data.sector_pathway || "");
            setSelectedTargetCoverage(data.target_coverage || "");
        } else {
            resetForm();
        }
    };

    const addLongTermToolInputs = async (templateId) => {
        try {
            const apiUrl = `${config.apiUrl}/add_data`;
            const body = JSON.stringify({
                table_name: "long_term_tool_inputs",
                data: {
                    template_id: templateId,
                    user_id: userId,
                    base_year: null,
                    target_year: null,
                    sector_pathway: null,
                    target_coverage: null
                },
            });

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: body,
            });

            const result = await response.json();
            if (result.status !== "success") {
                console.error("Failed to add new long term tool inputs:", result.message);
            }
        } catch (error) {
            console.error("Error adding new long term tool inputs:", error);
        }
    };

    const saveLongTermToolInputs = async () => {
        if (selectedRow && userId) {
            try {
                const apiUrl = `${config.apiUrl}/update_data`;
                const body = JSON.stringify({
                    table_name: "long_term_tool_inputs",
                    data: {
                        template_id: selectedRow.id,
                        base_year: baseYear,
                        target_year: targetYear,
                        sector_pathway: sectorPathway,
                        target_coverage: selectedTargetCoverage
                    },
                    condition: `template_id = '${selectedRow.id}'`,
                });

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: body,
                });

                const result = await response.json();

                if (result.status === "success") {
                    alert("Long-term tool inputs updated successfully!");
                    loadLongTermToolInputs();
                } else {
                    alert("Failed to save long-term tool inputs.");
                }
            } catch (error) {
                console.error("Error updating long-term tool inputs:", error);
                alert("Error saving long-term tool inputs.");
            }
        } else {
            alert("User ID is null. Cannot update long-term tool inputs.");
        }
    }

    const fetchData = () => {
        if (!userId) return;

        const condition = `user_id = '${userId}' AND template_id = '${selectedRow.id}' AND tool_type = 'long-term'`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                table_name: "net_zero_tool_actions",
                attribute: "*",
                condition: condition,
                include_blobs: true,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                setRowData(data)
                if (gridApiRef.current) {
                    gridApiRef.current.setRowData(data || []);
                }
            })
            .catch((error) => console.error("Error fetching data:", error));
    }

    const addRow = () => {
        if (!gridApiRef.current) return;

        if (actionYear === "") {
            window.alert("Select the year of the newly added action!");
            return;
        }

        const newRow = {
            user_id: userId,
            template_id: selectedRow.id,
            tool_type: "long-term",
            action: "",
            category: "",
            year: parseInt(actionYear, 10),
            details: "",
            estimated_ghg: 0,
        };

        fetch(`${config.apiUrl}/add_data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                table_name: "net_zero_tool_actions",
                data: newRow,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    gridApiRef.current.applyTransaction({add: [newRow]});
                    fetchData();
                }
            })
            .catch((error) => console.error("Error adding data:", error));
    };

    const deleteSelectedRows = () => {
        if (!gridApiRef.current) return;

        const selectedData = gridApiRef.current.getSelectedRows();

        selectedData.forEach((row) => {
            fetch(`${config.apiUrl}/delete_data`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({table_name: "net_zero_tool_actions", id: row.id}),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        gridApiRef.current.applyTransaction({remove: [row]});
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error deleting data:", error));
        });
    };

    const saveTable = () => {
        if (!gridApiRef.current) return;
        const allRows = gridApiRef.current.getRenderedNodes ? (gridApiRef.current.getRenderedNodes() || []).map((node) => node.data) : [];

        allRows.forEach((row) => {
            fetch(`${config.apiUrl}/update_data`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    table_name: "net_zero_tool_actions",
                    data: row,
                    condition: `id = '${row.id}'`,
                }),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error updating data:", error));
        });

        // setDisableSave(true);
    };

    const columnDefs = [
        {field: "id", hide: true},
        {
            headerCheckboxSelection: true, // Adds a checkbox in the header
            checkboxSelection: true,
            headerName: "Sl no.",
            field: "sno",
            width: 80,
            pinned: "left",
            valueGetter: (params) => params.node.rowIndex + 1,
            sortable: false,
            filter: false,
        },
        {
            headerName: "Action",
            field: "action",
            flex: 1,
            editable: true,
            cellEditor: "agTextCellEditor",
        },
        {
            headerName: "Term",
            field: "tool_type",
            flex: 1,
            editable: false,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Long-term", "Short-term", "Freight"],
            },
        },
        {
            headerName: "Category",
            field: "category",
            flex: 1,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Operational", "Strategic", "Tactical"],
            },
        },
        {
            headerName: "Year",
            field: "year",
            flex: 1,
            editable: false,
        },
        {
            headerName: "Details",
            field: "details",
            flex: 4,
            editable: true,
            cellEditor: "agTextCellEditor",
        },
        {
            headerName: "ESTIMATED GHG REDUCTIONS (ton CO₂e)",
            field: "estimated_ghg",
            flex: 1,
            editable: true,
            cellStyle: {textAlign: "right"},
            cellEditor: 'agTextCellEditor',
            cellEditorParams: {
                useFormatter: true,
                inputType: 'number',
            }
        }
    ];

    const freightToolGridProps = {
        columnDefs: columnDefs,
        rowData: rowData,
        rowSelection: "multiple",
        onGridReady: onGridReady,
        domLayout: "autoHeight",
    };

    const generateTable = (data) => {
        return new Table({
            columnWidths: [4000, 6000],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: data.map(row => new TableRow({
                children: row.map(cell => new TableCell({
                    children: [new Paragraph({text: cell, spacing: {after: 100}})],
                    width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                    },
                }))
            }))
        });
    };

    const longTermToolExport = async () => {
        try {
            const doc = new Document({
                sections: [
                    {
                        properties: {},
                        children: [
                            new Paragraph({
                                text: "Net Zero Long-Term Tool Report",
                                heading: HeadingLevel.TITLE,
                                alignment: AlignmentType.CENTER,
                            }),

                            new Paragraph({
                                text: "Section 1.1. Input data (absolute targets)",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {after: 200}
                            }),

                            generateTable([
                                ["Target Coverage", selectedTargetCoverage || "N/A"],
                                ["Target Setting Method", "Absolute contraction"],
                                ["Base Year", baseYear || "N/A"],
                                ["Target Year", targetYear || "N/A"],
                                ["Sector Pathway", sectorPathway || "N/A"],
                                ["Scope 1 Emissions", scope1Emission + " tCO₂e" || "0 tCO₂e"],
                                ["Scope 2 Emissions", scope2Emission + " tCO₂e" || "0 tCO₂e"],
                                ["Scope 3 Emissions", scope3Emission + " tCO₂e" || "0 tCO₂e"],
                                ["Total Emissions", totalEmissions + " tCO₂e" || "0.00 tCO₂e"],
                            ]),

                            new Paragraph({
                                text: "Section 1.2. Absolute target results",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {after: 200}
                            }),

                            generateTable([
                                ["", "Base Year", "Target Year", "% Intensity Reduction"],
                                ["Company | 0 intensity (tCO₂e/unit of value added)", totalEmissions.toFixed(2) || "0.00", (totalEmissions * (1 - percentageReduction)).toFixed(2) || "#N/A", ((percentageReduction * 100).toFixed(2) + "%")],
                                ["Long Term SBT formulation", longTermSBTFormulation],
                            ]),

                            new Paragraph({
                                text: "Actions Table",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 200},
                            }),

                            generateTable([
                                ["Sl no.", "Action", "Term", "Category", "Year", "Details", "ESTIMATED GHG REDUCTIONS (ton CO₂e)"],
                                ...rowData.map((action, index) => [
                                    String(index + 1),
                                    action.action || "N/A",
                                    action.tool_type || "N/A",
                                    action.category || "N/A",
                                    String(action.year) || "N/A",
                                    action.details || "N/A",
                                    String(action.estimated_ghg) || "N/A"
                                ])
                            ]),
                        ],
                    },
                ],
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, "Long_Term_Tool_Report.docx");
        } catch (error) {
            console.error("Error generating DOCX:", error);
            alert("An error occurred while generating the DOCX file.");
        }
    };

    return (
        <div className="absolute-target-section">
            <div className="net-zero-tool-header">
                <h2
                    onClick={toggleContainer}
                    onMouseEnter={() => document.querySelector('.net-zero-tool-header h2').style.color = 'grey'}
                    onMouseLeave={() => document.querySelector('.net-zero-tool-header h2').style.color = 'black'}
                    style={{cursor: 'pointer'}}
                >
                    {selectedText.netZeroLongTermToolTitle || 'Net Zero Long Term Tool'}
                </h2>
            </div>
            {isOpen && (<div className="tool-section">
                <div className="section">
                    <div className="freight-tool-button-container">
                        <button className="freight-tool-reset-button" onClick={resetForm}>Reset</button>
                        <button className="freight-tool-save-button" onClick={saveLongTermToolInputs}>Save</button>
                    </div>
                    <h3>Input data (absolute targets)</h3>
                    <div className="data-table">
                        <div className="table-row">
                            <div className="row label">Target coverage</div>
                            <select
                                className="input grey"
                                value={selectedTargetCoverage}
                                onChange={(e) => setSelectedTargetCoverage(e.target.value)}>
                                <option value="">Select an option</option>
                                <option value="Scope 1">Scope 1</option>
                                <option value="Scope 1 & 2">Scope 1 & 2</option>
                                <option value="Scope 3">Scope 3</option>
                                <option value="Scope 1,2 & 3">Scope 1,2 & 3</option>
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Target setting method</div>
                            <div className="input grey">Absolute contraction</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Base year</div>
                            <select
                                className="input grey"
                                value={baseYear}
                                onChange={(e) => setBaseYear(e.target.value)}
                            >
                                <option value="">Select a Year</option>
                                {baseYearList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Target year</div>
                            <select
                                className="input grey"
                                value={targetYear}
                                onChange={(e) => setTargetYear(e.target.value)}
                            >
                                <option value="">Select a Year</option>
                                {targetYearList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Sector pathway</div>
                            <select
                                className="input grey"
                                value={sectorPathway}
                                onChange={(e) => setSectorPathway(e.target.value)}
                            >
                                <option value="">Select a Sector Pathway</option>
                                {sectorPathwayList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="table-row">
                            <div className="row label">Scope 1 emissions (tCO₂e)</div>
                            <div className="input grey">{scope1Emission.toFixed(2)}</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Scope 2 emissions (tCO₂e)</div>
                            <div className="input grey">{scope2Emission.toFixed(2)}</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Scope 3 emissions (tCO₂e)</div>
                            <div className="input grey">{scope3Emission.toFixed(2)}</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">Total emissions in {selectedTargetCoverage} (tCO₂e)</div>
                            <div className="input grey">{totalEmissions.toFixed(2)}</div>
                        </div>
                    </div>
                </div>

                <div className="section">
                    <h3>Absolute target results</h3>
                    <div className="data-table">
                        <div className="table-row table-header">
                            <div className="header-column"></div>
                            <div className="header-column">Base year ({baseYear})</div>
                            <div className="header-column">Target year ({targetYear})</div>
                            <div className="header-column">% Intensity Reduction</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Company | Scopes 1,2 & 3 (tCO2e)</div>
                            <div className="input grey">{totalEmissions.toFixed(2) || "0.00"}</div>
                            <div
                                className="input grey">{(totalEmissions * (1 - percentageReduction)).toFixed(2) || "#N/A"}</div>
                            <div className="input grey">{(percentageReduction * 100).toFixed(2) + "%"}</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Long Term SBT formulation</div>
                            <div className="input grey full-width">{longTermSBTFormulation}</div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="data-table">
                        <div>
                            <div style={{marginBottom: "10px", display: "flex", gap: "10px"}}>
                                <button onClick={addRow} style={{
                                    padding: "8px 12px",
                                    background: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="ic:round-plus" style={{fontSize: "16px"}}/>
                                </button>
                                <button onClick={deleteSelectedRows} style={{
                                    padding: "8px 12px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="ic:round-minus" style={{fontSize: "16px"}}/>
                                </button>
                                <button onClick={saveTable} style={{
                                    padding: "8px 12px",
                                    background: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="mdi:floppy-disk" style={{fontSize: "16px"}}/>
                                </button>
                                <select
                                    className="action-year-select"
                                    value={actionYear}
                                    onChange={(e) => setActionYear(e.target.value)}
                                >
                                    <option value="">Select Action Year</option>
                                    {actionYearList.map((year, index) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                                <span className="action-year-remark">
                                    * Select action year between base year and target year
                                </span>
                            </div>
                            <Grid {...freightToolGridProps} />
                        </div>
                    </div>
                </div>
                <div className="net-zero-report-container">
                    <button onClick={longTermToolExport} className="generate-report-supplier-button">
                        <FiDownload size={18} style={{marginRight: '8px'}}/>
                        {`${selectedText.generateNetZeroReport} (Corporate Long-term Tool)`}
                    </button>
                </div>
            </div>)}
        </div>
    );
}

export default NetZeroBottomLongTermTool;
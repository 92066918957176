import '../style/components-style/NetZeroBottomTool.css'
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Grid from "../environment-page-components/Grid";
import {Icon} from "@iconify/react";
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import {Line} from "react-chartjs-2";
import config from "../config";
import {
    AlignmentType,
    Document,
    HeadingLevel,
    ImageRun,
    Packer,
    Paragraph,
    Table,
    TableCell,
    TableRow,
    WidthType
} from "docx";
import {saveAs} from 'file-saver';
import {FiDownload} from "react-icons/fi";
import {toPng} from "html-to-image";
import {useAuth} from "../authentication/AuthProvider";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function NetZeroBottomFreightTool({selectedText, selectedRow}) {
    const [isOpen, setIsOpen] = useState(true);
    const [baseYear, setBaseYear] = useState("");
    const [targetYear, setTargetYear] = useState("");
    const [actionYear, setActionYear] = useState("");
    const [intermediateYear, setIntermediateYear] = useState("");
    const [transportEmissionType, setTransportEmissionType] = useState("");
    const [transportCategory, setTransportCategory] = useState("");

    const [wellToWheelEmission, setWellToWheelEmission] = useState(0.0);
    const [baseYearActivity, setBaseYearActivity] = useState(0.0);
    const [expectedActivity, setExpectedActivity] = useState(0.0);
    const [wellToTankEmission, setWellToTankEmission] = useState(0.0);
    const [tankToWheelEmission, setTankToWheelEmission] = useState(0.0);

    const [rowData, setRowData] = useState([]);

    const [sectorGrowthRate, setSectorGrowthRate] = useState(0.0);

    const [expectedWTWIntensity, setExpectedWTWIntensity] = useState(0.0);
    const [expectedWTTIntensity, setExpectedWTTIntensity] = useState(0.0);
    const [expectedTTWIntensity, setExpectedTTWIntensity] = useState(0.0);
    const [expectedWTWEmission, setExpectedWTWEmission] = useState(0.0);
    const [expectedWTTEmission, setExpectedWTTEmission] = useState(0.0);
    const [expectedTTWEmission, setExpectedTTWEmission] = useState(0.0);

    const [intermediateYearActivity, setIntermediateYearActivity] = useState(0.0);
    const [intermediateWTWIntensity, setIntermediateWTWIntensity] = useState(0.0);
    const [intermediateWTTIntensity, setIntermediateWTTIntensity] = useState(0.0);
    const [intermediateTTWIntensity, setIntermediateTTWIntensity] = useState(0.0);
    const [intermediateWTWEmission, setIntermediateWTWEmission] = useState(0.0);
    const [intermediateWTTEmission, setIntermediateWTTEmission] = useState(0.0);
    const [intermediateTTWEmission, setIntermediateTTWEmission] = useState(0.0);

    const [fixedYearlyEmissions, setFixedYearlyEmissions] = useState({
        2030: null,
        2040: null,
        2050: null
    });
    const [fixedYearlyIndexedSectorEmissions, setFixedYearlyIndexedSectorEmissions] = useState({
        2030: null,
        2040: null,
        2050: null
    })
    const [fixedYearlyIntensity, setFixedYearlyIntensity] = useState({
        2030: null,
        2040: null,
        2050: null
    });

    const [tempWTWEmission, setTempWTWEmission] = useState("");
    const [tempBaseActivity, setTempBaseActivity] = useState("");
    const [tempExpectedActivity, setTempExpectedActivity] = useState("");
    const [tempWTTEmission, setTempWTTEmission] = useState("");
    const [tempTTWEmission, setTempTTWEmission] = useState("");

    const commitChange = (setter, tempValue) => {
        const numericValue = Number(tempValue);
        if (!isNaN(numericValue)) {
            setter(numericValue);
        }
    };

    const absoluteEmissionsGraphRef = useRef(null);
    const carbonIntensityGraphRef = useRef(null);

    const {user} = useAuth();
    const userId = user ? user.username : null;

    useEffect(() => {
        setIntermediateYearActivity((expectedActivity - baseYearActivity) / (targetYear - baseYear) * (intermediateYear - baseYear) + baseYearActivity);
    }, [baseYear, targetYear, intermediateYear, baseYearActivity, expectedActivity]);

    const fetchIntensity = async (year, activity, type) => {
        if (!baseYear || !year || !transportCategory || baseYearActivity === 0.0 || activity === 0.0) return;

        try {
            const url = `${config.apiUrl}/api/get-${type}-intensity`;

            const response = await fetch(url, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    baseYear,
                    targetYear: year,
                    transportCategory,
                    baseYearActivity,
                    expectedActivity: activity,
                    ...(type === "wtw" && {wellToWheelEmission}),
                    ...(type === "wtt" && {wellToTankEmission}),
                    ...(type === "ttw" && {tankToWheelEmission})
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Error fetching ${type.toUpperCase()} intensity: ${response.statusText}, Response: ${errorText}`);
                return;
            }

            return await response.json();
        } catch (error) {
            console.error(`Failed to fetch ${type.toUpperCase()} intensity data:`, error.message);
        }
    };

    useEffect(() => {
        fetchFixedYearlyData();
        const updateIntensities = async () => {
            const wtwTarget = await fetchIntensity(targetYear, expectedActivity, "wtw");
            const wttTarget = await fetchIntensity(targetYear, expectedActivity, "wtt");
            const ttwTarget = await fetchIntensity(targetYear, expectedActivity, "ttw");

            if (wtwTarget) setExpectedWTWIntensity(wtwTarget);
            if (wttTarget) setExpectedWTTIntensity(wttTarget);
            if (ttwTarget) setExpectedTTWIntensity(ttwTarget);
        };

        updateIntensities();
    }, [baseYear, targetYear, transportCategory, baseYearActivity, expectedActivity, wellToWheelEmission, wellToTankEmission, tankToWheelEmission]);

    useEffect(() => {
        const updateIntermediateIntensities = async () => {
            const wtwIntermediate = await fetchIntensity(intermediateYear, intermediateYearActivity, "wtw");
            const wttIntermediate = await fetchIntensity(intermediateYear, intermediateYearActivity, "wtt");
            const ttwIntermediate = await fetchIntensity(intermediateYear, intermediateYearActivity, "ttw");

            if (wtwIntermediate) setIntermediateWTWIntensity(wtwIntermediate);
            if (wttIntermediate) setIntermediateWTTIntensity(wttIntermediate);
            if (ttwIntermediate) setIntermediateTTWIntensity(ttwIntermediate);
        };

        updateIntermediateIntensities();
    }, [baseYear, transportCategory, baseYearActivity, intermediateYearActivity, wellToWheelEmission, wellToTankEmission, tankToWheelEmission, intermediateYear]);

    useEffect(() => {
        setExpectedWTWEmission(expectedWTWIntensity * expectedActivity / 1000 / 1000);
        setExpectedWTTEmission(expectedWTTIntensity * expectedActivity / 1000 / 1000);
        setExpectedTTWEmission(expectedTTWIntensity * expectedActivity / 1000 / 1000);
    }, [expectedWTWIntensity, expectedWTTIntensity, expectedTTWIntensity, expectedActivity]);

    useEffect(() => {
        setIntermediateWTWEmission(intermediateWTWIntensity * intermediateYearActivity / 1000 / 1000);
        setIntermediateWTTEmission(intermediateWTTIntensity * intermediateYearActivity / 1000 / 1000);
        setIntermediateTTWEmission(intermediateTTWIntensity * intermediateYearActivity / 1000 / 1000);
    }, [intermediateWTWIntensity, intermediateWTTIntensity, intermediateTTWIntensity, intermediateYearActivity]);

    useEffect(() => {
        setIntermediateYear("");
        setIntermediateWTWIntensity(0.0);
        setIntermediateWTTIntensity(0.0);
        setIntermediateTTWIntensity(0.0);
    }, [baseYear, targetYear]);

    const fetchSectorGrowthRate = async () => {
        if (!baseYear || !targetYear || !transportCategory) return;

        try {
            const url = `${config.apiUrl}/api/get-sector-growth-rate`;

            const response = await fetch(url, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    baseYear,
                    targetYear,
                    transportCategory
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error(`Error fetching sector growth rate: ${response.statusText}, Response: ${errorText}`);
                return;
            }

            const data = await response.json();

            setSectorGrowthRate(data);
        } catch (error) {
            console.error("Failed to fetch sector growth rate:", error.message);
        }
    }

    useEffect(() => {
        fetchSectorGrowthRate();
    }, [baseYear, targetYear, transportCategory])

    const baseYearWTWIntensity = useMemo(() => {
        return baseYearActivity !== 0.0 ? (wellToWheelEmission / baseYearActivity) * 1000 * 1000 : 0.0;
    }, [wellToWheelEmission, baseYearActivity]);

    const baseYearWTTIntensity = useMemo(() => {
        return baseYearActivity !== 0.0 ? (wellToTankEmission / baseYearActivity) * 1000 * 1000 : 0.0
    }, [wellToTankEmission, baseYearActivity]);

    const baseYearTTWIntensity = useMemo(() => {
        return baseYearActivity !== 0.0 ? (tankToWheelEmission / baseYearActivity) * 1000 * 1000 : 0.0;
    }, [tankToWheelEmission, baseYearActivity]);

    const fetchFixedYearlyData = async () => {
        if (!baseYear || !targetYear || !transportCategory || baseYearActivity === 0.0 || expectedActivity === 0.0) return;

        try {
            const url = `${config.apiUrl}/api/get-fixed-yearly-emissions`;

            const response = await fetch(url, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    baseYear,
                    targetYear,
                    transportCategory,
                    baseYearActivity,
                    expectedActivity,
                    wellToWheelEmission
                }),
            });

            if (!response.ok) {
                const text = await response.text();
                console.error(`Error fetching emissions data: ${response.statusText}, Response: ${text}`);
                return;
            }

            const data = await response.json(); // Process JSON response

            setFixedYearlyEmissions({
                2030: data.emissions["2030"] ?? 0,
                2040: data.emissions["2040"] ?? 0,
                2050: data.emissions["2050"] ?? 0
            });

            setFixedYearlyIndexedSectorEmissions({
                2030: data.indexedSectorEmissions["2030"] ?? 0,
                2040: data.indexedSectorEmissions["2040"] ?? 0,
                2050: data.indexedSectorEmissions["2050"] ?? 0
            });

            setFixedYearlyIntensity({
                2030: data.intensity["2030"] ?? 0,
                2040: data.intensity["2040"] ?? 0,
                2050: data.intensity["2050"] ?? 0
            });

        } catch (error) {
            console.error("Failed to fetch emissions data:", error.message);
        }
    };

    const gridApiRef = useRef(null);

    const baseYearList = Array.from({length: 11}, (_, i) => (2015 + i).toString());
    const targetYearList = Array.from({length: 26}, (_, i) => (2025 + i).toString());
    const transportCategories = [
        "Freight - 2-3 Wheelers",
        "Freight - Light commercial vehicles",
        "Freight - Medium freight trucks (MFT)",
        "Freight - Heavy freight trucks (HFT)",
        "Total heavy freight road (MFT & HFT)",
        "Freight - Rail"
    ];

    const actionYearList = useMemo(() => {
        if (!baseYear || !targetYear) return []; // Return empty list if years are not set

        const start = parseInt(baseYear, 10);
        const end = parseInt(targetYear, 10);

        if (isNaN(start) || isNaN(end) || start >= end) return []; // Ensure valid years

        return Array.from({length: end - start}, (_, i) => start + i);
    }, [baseYear, targetYear]);

    const toggleContainer = () => setIsOpen(!isOpen);

    const onGridReady = useCallback((params) => {
        gridApiRef.current = params.api;
    }, []);

    const resetForm = () => {
        setSectorGrowthRate(0.0);
        setTransportEmissionType("");
        setTransportCategory("");
        setBaseYear("");
        setTargetYear("");
        setWellToWheelEmission(0.0);
        setBaseYearActivity(0.0);
        setExpectedActivity(0.0);
        setWellToTankEmission(0.0);
        setTankToWheelEmission(0.0);
        setExpectedWTWIntensity(0.0);
        setExpectedWTTIntensity(0.0);
        setExpectedTTWIntensity(0.0);
        setIntermediateWTWIntensity(0.0);
        setIntermediateWTTIntensity(0.0);
        setIntermediateTTWIntensity(0.0);
        setFixedYearlyEmissions({
            2030: null,
            2040: null,
            2050: null
        });
        setFixedYearlyIndexedSectorEmissions({
            2030: null,
            2040: null,
            2050: null
        });
        setFixedYearlyIntensity({
            2030: null,
            2040: null,
            2050: null
        });
    };

    const fetchFreightToolInputs = async (templateId) => {
        try {
            const response = await fetch(`${config.apiUrl}/fetch_data`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    table_name: "freight_tool_inputs",
                    condition: `template_id = '${templateId}'`,
                }),
            });
            const ghgInfoData = await response.json();

            if (!ghgInfoData || ghgInfoData.length === 0) {
                await addFreightToolInputs(templateId);
            }

            return ghgInfoData;
        } catch (error) {
            console.error("Error fetching freight tool inputs:", error);
            return null;
        }
    };

    const loadFreightToolInputs = async () => {
        if (!selectedRow || !selectedRow.id) {
            resetForm();
            return;
        }

        const freightToolInputsData = await fetchFreightToolInputs(selectedRow.id);

        if (freightToolInputsData && freightToolInputsData.length > 0) {
            const data = freightToolInputsData[0];

            setTransportEmissionType(data.transport_emission_type || '');
            setTransportCategory(data.transport_category || '');
            setBaseYear(data.base_year || '');
            setTargetYear(data.target_year || '');
            setWellToWheelEmission(data.wtw_emission || 0.0);
            setBaseYearActivity(data.base_year_activity || 0.0);
            setExpectedActivity(data.expected_activity || 0.0);
            setWellToTankEmission(data.wtt_emission || 0.0);
            setTankToWheelEmission(data.ttw_emission || 0.0);
        } else {
            resetForm();
        }
    };

    const addFreightToolInputs = async (templateId) => {
        try {
            const apiUrl = `${config.apiUrl}/add_data`;
            const body = JSON.stringify({
                table_name: "freight_tool_inputs",
                data: {
                    template_id: templateId,
                    user_id: userId,
                    transport_emission_type: null,
                    transport_category: null,
                    base_year: null,
                    target_year: null,
                    wtw_emission: null,
                    base_year_activity: null,
                    expected_activity: null,
                    wtt_emission: null,
                    ttw_emission: null
                },
            });

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: body,
            });

            const result = await response.json();
            if (result.status !== "success") {
                console.error("Failed to add new freight tool inputs:", result.message);
            }
        } catch (error) {
            console.error("Error adding new freight tool inputs:", error);
        }
    };

    const saveFreightToolInputs = async () => {
        if (selectedRow && userId) {
            try {
                const apiUrl = `${config.apiUrl}/update_data`;
                const body = JSON.stringify({
                    table_name: "freight_tool_inputs",
                    data: {
                        template_id: selectedRow.id,
                        transport_emission_type: transportEmissionType,
                        transport_category: transportCategory,
                        base_year: baseYear,
                        target_year: targetYear,
                        wtw_emission: wellToWheelEmission,
                        base_year_activity: baseYearActivity,
                        expected_activity: expectedActivity,
                        wtt_emission: wellToTankEmission,
                        ttw_emission: tankToWheelEmission
                    },
                    condition: `template_id = '${selectedRow.id}'`,
                });

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: body,
                });

                const result = await response.json();

                if (result.status === "success") {
                    alert("Freight tool inputs updated successfully!");
                    loadFreightToolInputs();
                } else {
                    alert("Failed to save freight tool inputs.");
                }
            } catch (error) {
                console.error("Error updating freight tool inputs:", error);
                alert("Error saving freight tool inputs.");
            }
        } else {
            alert("User ID is null. Cannot update freight tool inputs.");
        }
    }

    useEffect(() => {
        if (!selectedRow || !selectedRow.id) {
            setRowData([]);
            return;
        }
        loadFreightToolInputs();
    }, [selectedRow]);

    const fetchData = () => {
        if (!userId) return;

        const condition = `user_id = '${userId}' AND template_id = '${selectedRow.id}' AND tool_type = 'freight'`;

        fetch(`${config.apiUrl}/fetch_data`, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                table_name: "net_zero_tool_actions",
                attribute: "*",
                condition: condition,
                include_blobs: true,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                setRowData(data)
                if (gridApiRef.current) {
                    gridApiRef.current.setRowData(data || []);
                }
            })
            .catch((error) => console.error("Error fetching data:", error));
    }

    const addRow = () => {
        if (!gridApiRef.current) return;

        if (actionYear === "") {
            window.alert("Select the year of the newly added action!");
            return;
        }

        const newRow = {
            user_id: userId,
            template_id: selectedRow.id,
            tool_type: "freight",
            action: "",
            category: "",
            year: parseInt(actionYear, 10),
            details: "",
            estimated_ghg: 0,
        };

        fetch(`${config.apiUrl}/add_data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                table_name: "net_zero_tool_actions",
                data: newRow,
            }),
        })
            .then((response) => {
                if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                return response.json();
            })
            .then((data) => {
                if (data.status === "success") {
                    gridApiRef.current.applyTransaction({add: [newRow]});
                    fetchData();
                }
            })
            .catch((error) => console.error("Error adding data:", error));
    };

    const deleteSelectedRows = () => {
        if (!gridApiRef.current) return;

        const selectedData = gridApiRef.current.getSelectedRows();

        selectedData.forEach((row) => {
            fetch(`${config.apiUrl}/delete_data`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({table_name: "net_zero_tool_actions", id: row.id}),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        gridApiRef.current.applyTransaction({remove: [row]});
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error deleting data:", error));
        });
    };

    const saveTable = () => {
        if (!gridApiRef.current) return;
        const allRows = gridApiRef.current.getRenderedNodes ? (gridApiRef.current.getRenderedNodes() || []).map((node) => node.data) : [];

        allRows.forEach((row) => {
            fetch(`${config.apiUrl}/update_data`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    table_name: "net_zero_tool_actions",
                    data: row,
                    condition: `id = '${row.id}'`,
                }),
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Network response was not ok: " + response.statusText);
                    return response.json();
                })
                .then((data) => {
                    if (data.status === "success") {
                        fetchData();
                    }
                })
                .catch((error) => console.error("Error updating data:", error));
        });

        // setDisableSave(true);
    };

    const columnDefs = [
        {field: "id", hide: true},
        {
            headerCheckboxSelection: true, // Adds a checkbox in the header
            checkboxSelection: true,
            headerName: "Sl no.",
            field: "sno",
            width: 80,
            pinned: "left",
            valueGetter: (params) => params.node.rowIndex + 1,
            sortable: false,
            filter: false,
        },
        {
            headerName: "Action",
            field: "action",
            flex: 1,
            editable: true,
            cellEditor: "agTextCellEditor",
        },
        {
            headerName: "Term",
            field: "tool_type",
            flex: 1,
            editable: false,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Long-term", "Short-term", "Freight"],
            },
        },
        {
            headerName: "Category",
            field: "category",
            flex: 1,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellEditorParams: {
                values: ["Operational", "Strategic", "Tactical"],
            },
        },
        {
            headerName: "Year",
            field: "year",
            flex: 1,
            editable: false,
        },
        {
            headerName: "Details",
            field: "details",
            flex: 4,
            editable: true,
            cellEditor: "agTextCellEditor",
        },
        {
            headerName: "ESTIMATED GHG REDUCTIONS (ton CO₂e)",
            field: "estimated_ghg",
            flex: 1,
            editable: true,
            cellStyle: {textAlign: "right"},
            cellEditor: 'agTextCellEditor',
            cellEditorParams: {
                useFormatter: true,
                inputType: 'number',
            }
        }
    ];

    const freightToolGridProps = {
        columnDefs: columnDefs,
        rowData: rowData,
        rowSelection: "multiple",
        onGridReady: onGridReady,
        domLayout: "autoHeight",
    };

    const absoluteEmissionsOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "linear",
                title: {display: true, text: "Year"},
                grid: {display: false},
                ticks: {
                    stepSize: 10,
                },
            },
            y: {
                type: "linear",
                position: "left",
                title: {display: true, text: "Metric tonnes of CO₂ eq"},
                grid: {color: "rgba(200, 200, 200, 0.3)"},
            },
            y1: {
                type: "linear",
                position: "right",
                grid: {drawOnChartArea: false},
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            datalabels: {
                formatter: (value) => {
                    if (typeof value === "number" && !isNaN(value)) {
                        return value.toFixed(2);
                    }
                    return value;
                },
            },
        },
    };

    const carbonIntensityOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "linear",
                title: {display: true, text: "Year"},
                grid: {display: false},
                ticks: {
                    stepSize: 5,
                },
            },
            y: {
                title: {display: true, text: "gCO2e/pkm"},
                grid: {color: "rgba(200, 200, 200, 0.3)"},
            },
        },
        plugins: {
            legend: {
                display: true,
                position: "top",
            },
            datalabels: {
                formatter: (value) => {
                    if (typeof value === "object" && value !== null && "y" in value) {
                        return value.y.toFixed(2);
                    }
                    if (typeof value === "number" && !isNaN(value)) {
                        return value.toFixed(2);
                    }
                    return value;
                },
            },
        },
    };

    const absoluteEmissionsData = {
        labels: [baseYear, 2030, 2040, 2050].sort((a, b) => a - b),
        datasets: [
            {
                label: `Company WTW Emissions`,
                data: [
                    wellToWheelEmission,
                    fixedYearlyEmissions[2030],
                    fixedYearlyEmissions[2040],
                    fixedYearlyEmissions[2050]
                ],
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                tension: 0.3,
                yAxisID: "y",
            },
            {
                label: `Sector WTW Emissions (Indexed)`,
                data: [
                    1.0, // Example alternative emission scenario
                    fixedYearlyIndexedSectorEmissions[2030],
                    fixedYearlyIndexedSectorEmissions[2040],
                    fixedYearlyIndexedSectorEmissions[2050]
                ],
                borderColor: "rgba(255, 99, 132, 1)", // Different color for second line
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                tension: 0.3,
                yAxisID: "y1",
            }
        ],
    };

    const carbonIntensityData = {
        labels: [baseYear, 2030, 2040, 2050].sort((a, b) => a - b),
        datasets: [
            {
                label: `Company WTW Carbon Intensity`,
                data: [
                    wellToWheelEmission / baseYearActivity * 1000000,
                    fixedYearlyIntensity[2030],
                    fixedYearlyIntensity[2040],
                    fixedYearlyIntensity[2050]
                ],
                borderColor: "rgba(75, 192, 192, 1)",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                tension: 0.3,
                showLine: true,
            },
            {
                label: "Target year carbon intensity",
                data: [{x: targetYear, y: expectedWTWIntensity}],
                backgroundColor: "red",
                borderColor: "red",
                pointRadius: 6,
                pointHoverRadius: 8,
                showLine: false,
            }
        ],
    };

    const generateTable = (data) => {
        return new Table({
            columnWidths: [4000, 6000],
            width: {
                size: 100,
                type: WidthType.PERCENTAGE,
            },
            rows: data.map(row => new TableRow({
                children: row.map(cell => new TableCell({
                    children: [new Paragraph({text: cell, spacing: {after: 100}})],
                    width: {
                        size: 50,
                        type: WidthType.PERCENTAGE,
                    },
                }))
            }))
        });
    };

    const freightToolExport = async (absoluteEmissionsGraphRef, carbonIntensityGraphRef) => {
        try {
            const absoluteEmissionsUrl = await toPng(absoluteEmissionsGraphRef.current, {quality: 1});
            const carbonIntensityUrl = await toPng(carbonIntensityGraphRef.current, {quality: 1});


            const doc = new Document({
                sections: [
                    {
                        properties: {},
                        children: [
                            new Paragraph({
                                text: "Net Zero Freight Tool Report",
                                heading: HeadingLevel.TITLE,
                                alignment: AlignmentType.CENTER,
                            }),

                            new Paragraph({
                                text: "Section 1.1. Input Data (Absolute Targets)",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {after: 200}
                            }),

                            generateTable([
                                ["Transport Category", transportCategory || "N/A"],
                                ["Base Year", baseYear || "N/A"],
                                ["Target Year", targetYear || "N/A"],
                                ["Well to Wheel Emissions", wellToWheelEmission + " Metric tonnes of CO₂ eq." || "0 Metric tonnes of CO₂ eq."],
                                ["Activity in Base Year", baseYearActivity + " tkm" || "0 tkm"],
                                ["Expected Activity in Target Year", expectedActivity + " tkm" || "0 tkm"],
                                ["Well to Tank Emissions", wellToTankEmission + " Metric tonnes of CO₂ eq." || "0 Metric tonnes of CO₂ eq."],
                                ["Tank to Wheel Emissions", tankToWheelEmission + " Metric tonnes of CO₂ eq." || "0 Metric tonnes of CO₂ eq."]
                            ]),

                            new Paragraph({
                                text: "Section 1.2. Sector Growth Rate - B2DS",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {after: 200}
                            }),

                            generateTable([
                                ["Sector Information", `${baseYear} - ${targetYear}`],
                                [
                                    transportCategory === "" ? "Not Available" : "Sector Growth Rate - B2DS (tkm)",
                                    sectorGrowthRate ? `${sectorGrowthRate.toFixed(2)}%` : "N/A"
                                ],
                            ]),

                            new Paragraph({
                                text: "Section 1.3. Target Modelling Results (B2DS)",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {after: 200}
                            }),

                            generateTable([
                                ["Category", "Unit", "Base Year", "Target Year", "% Reduction"],
                                ["WTW Emissions", "Metric tonnes of CO₂ eq.", String(wellToWheelEmission) || "N/A", expectedWTWEmission.toFixed(2) || "N/A", `${((1 - expectedWTWEmission / wellToWheelEmission) * 100).toFixed(2)}%`],
                                ["WTW Carbon Intensity", "gCO₂e/tkm", String(baseYearWTWIntensity) || "N/A", expectedWTWIntensity.toFixed(2) || "N/A", `${((1 - expectedWTWIntensity / baseYearWTWIntensity) * 100).toFixed(2)}%`],
                                ["WTT Emissions", "Metric tonnes of CO₂ eq.", String(wellToTankEmission) || "N/A", expectedWTTEmission.toFixed(2) || "N/A", `${((1 - expectedWTTEmission / wellToTankEmission) * 100).toFixed(2)}%`],
                                ["WTT Carbon Intensity", "gCO₂e/tkm", String(baseYearWTTIntensity) || "N/A", expectedWTTIntensity.toFixed(2) || "N/A", `${((1 - expectedWTTIntensity / baseYearWTTIntensity) * 100).toFixed(2)}%`],
                                ["TTW Emissions", "Metric tonnes of CO₂ eq.", String(tankToWheelEmission) || "N/A", expectedTTWEmission.toFixed(2) || "N/A", `${((1 - expectedTTWEmission / tankToWheelEmission) * 100).toFixed(2)}%`],
                                ["TTW Carbon Intensity", "gCO₂e/tkm", String(baseYearTTWIntensity) || "N/A", expectedTTWIntensity.toFixed(2) || "N/A", `${((1 - expectedTTWIntensity / baseYearTTWIntensity) * 100).toFixed(2)}%`],
                            ]),

                            new Paragraph({
                                text: "Section 1.4. Graphs",
                                spacing: {after: 200},
                                heading: HeadingLevel.HEADING_1,
                            }),

                            new Paragraph({
                                text: "Absolute Emission (B2DS)",
                                spacing: {after: 100},
                                heading: HeadingLevel.HEADING_2,
                            }),
                            absoluteEmissionsUrl ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: absoluteEmissionsUrl,
                                        transformation: {
                                            width: 650,
                                            height: 300,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No absolute emissions graph available.',
                            }),

                            new Paragraph({
                                text: "Carbon Intensity (B2DS)",
                                spacing: {after: 100},
                                heading: HeadingLevel.HEADING_2,
                            }),
                            carbonIntensityUrl ? new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: carbonIntensityUrl,
                                        transformation: {
                                            width: 650,
                                            height: 300,
                                        },
                                    }),
                                ],
                            }) : new Paragraph({
                                text: 'No carbon intensity graph available.',
                            }),

                            new Paragraph({
                                text: "Actions Table",
                                heading: HeadingLevel.HEADING_1,
                                spacing: {before: 200, after: 200},
                            }),

                            generateTable([
                                ["Sl no.", "Action", "Term", "Category", "Year", "Details", "ESTIMATED GHG REDUCTIONS (ton CO₂e)"],
                                ...rowData.map((action, index) => [
                                    String(index + 1),
                                    action.action || "N/A",
                                    action.tool_type || "N/A",
                                    action.category || "N/A",
                                    String(action.year) || "N/A",
                                    action.details || "N/A",
                                    String(action.estimated_ghg) || "N/A"
                                ])
                            ]),
                        ],
                    },
                ],
            });

            const blob = await Packer.toBlob(doc);
            saveAs(blob, "Freight_Tool_Report.docx");
        } catch (error) {
            console.error("Error generating DOCX:", error);
            alert("An error occurred while generating the DOCX file.");
        }
    };

    return (
        <div className="absolute-target-section">
            <div className="net-zero-tool-header">
                <h2
                    onClick={toggleContainer}
                    onMouseEnter={() => document.querySelector('.net-zero-tool-header h2').style.color = 'grey'}
                    onMouseLeave={() => document.querySelector('.net-zero-tool-header h2').style.color = 'black'}
                    style={{cursor: 'pointer'}}
                >
                    {selectedText.netZeroFreightToolTitle || 'Net Zero Freight Tool'}
                </h2>
            </div>
            {isOpen && (<div className="tool-section">
                <div className="section">
                    <div className="freight-tool-button-container">
                        <button className="freight-tool-reset-button" onClick={resetForm}>Reset</button>
                        <button className="freight-tool-save-button" onClick={saveFreightToolInputs}>Save</button>
                    </div>
                    <h3>Section 1. Select a type of transport related Emission (Emission category for target
                        modelling)</h3>
                    <div className="data-table">
                        <div className="table-row">
                            <select className="input grey" value={transportEmissionType}
                                    onChange={(e) => setTransportEmissionType(e.target.value)}>
                                <option value="">Select a type of transport related emission</option>
                                <option value="Freight Transport Emissions">Freight transport emissions</option>
                                <option value="Passenger Transport Emissions">Passenger transport emissions</option>
                            </select>
                        </div>
                    </div>
                    <h3>Section 2. Select a transport category (Please refer to guidance document)</h3>
                    <div className="data-table">
                        <div className="table-row">
                            <select
                                className="input grey"
                                value={transportCategory}
                                onChange={(e) => setTransportCategory(e.target.value)}
                            >
                                <option value="">Select a transport category</option>
                                {transportCategories.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <h3>Section 3. Enter target details</h3>
                    <div className="data-table">
                        <div className="table-row">
                            <div className="row label">Select a base year</div>
                            <select
                                className="input grey"
                                value={baseYear}
                                onChange={(e) => setBaseYear(e.target.value)}
                            >
                                <option value="">Select a Year</option>
                                {baseYearList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                            <div className="unit-label">Any base year between 2015 and the current year is eligible
                            </div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Select a target year</div>
                            <select
                                className="input grey"
                                value={targetYear}
                                onChange={(e) => setTargetYear(e.target.value)}
                            >
                                <option value="">Select a Year</option>
                                {targetYearList.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                            <div className="unit-label">Any target year between 5 and 10 years from year of
                                submission
                                is
                                eligible
                            </div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Well to Wheel emissions in base year</div>
                            <input
                                type="number"
                                className="input grey"
                                value={tempWTWEmission}
                                onChange={(e) => setTempWTWEmission(e.target.value)}
                                onBlur={() => commitChange(setWellToWheelEmission, tempWTWEmission)}
                                onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <div className="unit-label">Metric tonnes of CO₂ eq.</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Activity in base year</div>
                            <input
                                type="number"
                                className="input grey"
                                value={tempBaseActivity}
                                onChange={(e) => setTempBaseActivity(e.target.value)}
                                onBlur={() => commitChange(setBaseYearActivity, tempBaseActivity)}
                                onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <div className="unit-label">tkm</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Expected activity in target year</div>
                            <input
                                type="number"
                                className="input grey"
                                value={tempExpectedActivity}
                                onChange={(e) => setTempExpectedActivity(e.target.value)}
                                onBlur={() => commitChange(setExpectedActivity, tempExpectedActivity)}
                                onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <div className="unit-label">tkm</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Well to Tank emissions in base year</div>
                            <input
                                type="number"
                                className="input grey"
                                value={tempWTTEmission}
                                onChange={(e) => setTempWTTEmission(e.target.value)}
                                onBlur={() => commitChange(setWellToTankEmission, tempWTTEmission)}
                                onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <div className="unit-label">Metric tonnes of CO₂ eq.</div>
                        </div>

                        <div className="table-row">
                            <div className="row label">Tank to Wheel emissions in base year</div>
                            <input
                                type="number"
                                className="input grey"
                                value={tempTTWEmission}
                                onChange={(e) => setTempTTWEmission(e.target.value)}
                                onBlur={() => commitChange(setTankToWheelEmission, tempTTWEmission)}
                                onKeyDown={(e) => e.key === "Enter" && e.target.blur()}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                            <div className="unit-label">Metric tonnes of CO₂ eq.</div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    {/* Title */}
                    <h3>Target modelling results - B2DS</h3>

                    {/* Sector Information Table */}
                    <div className="data-table">
                        <div className="table-row table-header">
                            <div className="header-column">Sector information</div>
                            <div className="header-column">{baseYear} - {targetYear}</div>
                        </div>
                        <div className="table-row">
                            <div className="row label">
                                {transportCategory === "" ? "Not Available" : "Sector growth rate - B2DS (tkm)"}
                            </div>
                            <div className="input grey">{sectorGrowthRate.toFixed(2)}%</div>
                        </div>
                    </div>

                    {/* Sectoral Decarbonization Approach Table */}
                    <div className="data-table">
                        <h3>Sectoral Decarbonization Approach (B2DS)</h3>
                        {/* Column Headers */}
                        <div className="table-row table-header">
                            <div className="header-column">Category</div>
                            <div className="header-column">Unit</div>
                            <div className="header-column">Base year {baseYear}</div>
                            <div className="header-column">
                                Intermediate Year
                                <select
                                    className="input grey"
                                    value={intermediateYear}
                                    onChange={(e) => setIntermediateYear(e.target.value)}
                                >
                                    <option value="">Select Intermediate Year</option>
                                    {actionYearList.slice(1).map((year) => (
                                        <option key={year} value={year}>{year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="header-column">Target year {targetYear}</div>
                            <div className="header-column">% Reduction {baseYear} - {targetYear}</div>
                        </div>

                        {/* Data Rows */}
                        {[
                            {
                                label: "WTW emissions",
                                unit: "Metric tonnes of CO₂ eq.",
                                base: wellToWheelEmission.toFixed(2),
                                intermediate: intermediateYear ? intermediateWTWEmission?.toFixed(2) || "N/A" : "N/A",
                                target: expectedWTWEmission.toFixed(2),
                                reduction: `${((1 - expectedWTWEmission / wellToWheelEmission) * 100).toFixed(2)}%`
                            },
                            {
                                label: "WTW carbon intensity",
                                unit: "gCO₂e/tkm",
                                base: baseYearWTWIntensity.toFixed(2),
                                intermediate: intermediateYear ? intermediateWTWIntensity.toFixed(2) || "N/A" : "N/A",
                                target: expectedWTWIntensity.toFixed(2),
                                reduction: `${((1 - expectedWTWIntensity / baseYearWTWIntensity) * 100).toFixed(2)}%`
                            },
                            {
                                label: "WTT emissions",
                                unit: "Metric tonnes of CO₂ eq.",
                                base: wellToTankEmission.toFixed(2),
                                intermediate: intermediateYear ? intermediateWTTEmission.toFixed(2) || "N/A" : "N/A",
                                target: expectedWTTEmission.toFixed(2),
                                reduction: `${((1 - expectedWTTEmission / wellToTankEmission) * 100).toFixed(2)}%`
                            },
                            {
                                label: "WTT carbon intensity",
                                unit: "gCO₂e/tkm",
                                base: baseYearWTTIntensity.toFixed(2),
                                intermediate: intermediateYear ? intermediateWTTIntensity.toFixed(2) || "N/A" : "N/A",
                                target: expectedWTTIntensity.toFixed(2),
                                reduction: `${((1 - expectedWTTIntensity / baseYearWTTIntensity) * 100).toFixed(2)}%`
                            },
                            {
                                label: "TTW emissions",
                                unit: "Metric tonnes of CO₂ eq.",
                                base: tankToWheelEmission.toFixed(2),
                                intermediate: intermediateYear ? intermediateTTWEmission.toFixed(2) || "N/A" : "N/A",
                                target: expectedTTWEmission.toFixed(2),
                                reduction: `${((1 - expectedTTWEmission / tankToWheelEmission) * 100).toFixed(2)}%`
                            },
                            {
                                label: "TTW carbon intensity",
                                unit: "gCO₂e/tkm",
                                base: baseYearTTWIntensity.toFixed(2),
                                intermediate: intermediateYear ? intermediateTTWIntensity.toFixed(2) || "N/A" : "N/A",
                                target: expectedTTWIntensity.toFixed(2),
                                reduction: `${((1 - expectedTTWIntensity / baseYearTTWIntensity) * 100).toFixed(2)}%`
                            }
                        ].map((row, index) => (
                            <div className="table-row" key={index}>
                                <div className="row label">{row.label}</div>
                                <div className="input transparent">{row.unit}</div>
                                <div className="input grey">{row.base}</div>
                                <div className="input grey">{row.intermediate}</div>
                                <div className="input grey">{row.target}</div>
                                <div className="input grey">{row.reduction}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="freight-tool-chart-container">
                    <div ref={absoluteEmissionsGraphRef} className="freight-tool-chart-box">
                        <h3>{transportCategory} - Absolute Emission (B2DS)</h3>
                        <Line data={absoluteEmissionsData}
                              options={absoluteEmissionsOptions}/>
                    </div>
                    <div ref={carbonIntensityGraphRef} className="freight-tool-chart-box">
                        <h3>{transportCategory} - Carbon Intensity (B2DS)</h3>
                        <Line data={carbonIntensityData}
                              options={carbonIntensityOptions}/>
                    </div>
                </div>
                <div className="section">
                    <div className="data-table">
                        <div>
                            <div style={{marginBottom: "10px", display: "flex", gap: "10px"}}>
                                <button onClick={addRow} style={{
                                    padding: "8px 12px",
                                    background: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="ic:round-plus" style={{fontSize: "16px"}}/>
                                </button>
                                <button onClick={deleteSelectedRows} style={{
                                    padding: "8px 12px",
                                    background: "red",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="ic:round-minus" style={{fontSize: "16px"}}/>
                                </button>
                                <button onClick={saveTable} style={{
                                    padding: "8px 12px",
                                    background: "green",
                                    color: "white",
                                    border: "none",
                                    cursor: "pointer"
                                }}>
                                    <Icon icon="mdi:floppy-disk" style={{fontSize: "16px"}}/>
                                </button>
                                <select
                                    className="action-year-select"
                                    value={actionYear}
                                    onChange={(e) => setActionYear(e.target.value)}
                                >
                                    <option value="">Select Action Year</option>
                                    {actionYearList.map((year, index) => (
                                        <option key={index} value={year}>{year}</option>
                                    ))}
                                </select>
                                <span className="action-year-remark">
                                    * Select action year between base year and target year
                                </span>
                            </div>
                            <Grid {...freightToolGridProps} />
                        </div>
                    </div>
                </div>
                <div className="net-zero-report-container">
                    <button onClick={() => freightToolExport(absoluteEmissionsGraphRef, carbonIntensityGraphRef)}
                            className="generate-report-supplier-button">
                        <FiDownload size={18} style={{marginRight: '8px'}}/>
                        {`${selectedText.generateNetZeroReport} (Corporate Tool)`}
                    </button>
                </div>
            </div>)}
        </div>
    );
}

export default NetZeroBottomFreightTool;
import "../style/components-style/NetZeroBottomTool.css"
import React, {useEffect} from "react";

const targetSettingMethods = [
    "Absolute Contraction Approach",
    "Economic intensity",
    "Physical intensity",
    "Cement SDA 1.5ºC"
]

const targetYearCementIntensities = {
    2028: 0.29,
    2029: 0.28,
    2030: 0.27,
    2031: 0.26,
    2032: 0.24,
    2033: 0.23,
    2034: 0.22,
    2035: 0.20
};

function Scope3ShortTermTool({
                                 resetForm,
                                 saveShortTermToolInputs,
                                 targetSettingMethod,
                                 setTargetSettingMethod,
                                 baseYear,
                                 setBaseYear,
                                 baseYearList,
                                 targetYear,
                                 setTargetYear,
                                 targetYearList,
                                 scope3Emission,
                                 yearsList,
                                 baseYearOutput,
                                 setBaseYearOutput,
                                 targetYearOutput,
                                 setTargetYearOutput,
                                 economicIntensityBase,
                                 setEconomicIntensityBase,
                                 economicIntensityTarget,
                                 setEconomicIntensityTarget,
                                 economicIntensityReduction,
                                 setEconomicIntensityReduction,
                                 cementTargetPhysicalIntensity,
                                 setCementTargetPhysicalIntensity
                             }) {

    useEffect(() => {
        if (!baseYearOutput || baseYearOutput === 0) {
            setEconomicIntensityBase(NaN);
            return;
        }
        setEconomicIntensityBase(scope3Emission / baseYearOutput);
    }, [scope3Emission, baseYearOutput]);

    useEffect(() => {
        const startYear = parseInt(baseYear, 10);
        const endYear = parseInt(targetYear, 10);

        if (isNaN(startYear) || isNaN(endYear) || startYear >= endYear) {
            setEconomicIntensityReduction(NaN)
        }

        if (startYear <= 2020) {
            setEconomicIntensityReduction((1 - Math.pow(1 - 0.07, endYear - startYear)))
        } else {
            setEconomicIntensityReduction((1 - Math.pow(1 - 0.07, endYear - 2020)))
        }
    }, [baseYear, targetYear]);

    useEffect(() => {
        if (!isNaN(economicIntensityReduction) && !isNaN(economicIntensityBase)) {
            setEconomicIntensityTarget(economicIntensityBase * (1 - economicIntensityReduction));
        }
    }, [economicIntensityBase, economicIntensityReduction]);

    useEffect(() => {
        const endYear = parseInt(targetYear, 10);
        if (!isNaN(endYear) && endYear >= 2028 && endYear <= 2035) {
            setCementTargetPhysicalIntensity(targetYearCementIntensities[endYear]);
        }
    }, [targetYear]);

    return (
        <>
            <div className="section">
                <div className="freight-tool-button-container">
                    <button className="freight-tool-reset-button" onClick={resetForm}>Reset</button>
                    <button className="freight-tool-save-button" onClick={saveShortTermToolInputs}>Save</button>
                </div>
                <h3>Input data</h3>
                <div className="data-table">
                    <div className="table-row">
                        <div className="row label">Target setting method</div>
                        <select
                            className="input grey"
                            value={targetSettingMethod}
                            onChange={(e) => setTargetSettingMethod(e.target.value)}
                        >
                            {targetSettingMethods.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-row">
                        <div className="row label">Base year</div>
                        <select
                            className="input grey"
                            value={baseYear}
                            onChange={(e) => setBaseYear(e.target.value)}
                        >
                            <option value="">Select a Year</option>
                            {baseYearList.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="table-row">
                        <div className="row label">Base Year | Scope 3 emissions (tCO₂e)</div>
                        <div className="input grey">{scope3Emission.toFixed(2)}</div>
                    </div>
                    <div className="table-row">
                        <div className="row label">Target year</div>
                        <select
                            className="input grey"
                            value={targetYear}
                            onChange={(e) => setTargetYear(e.target.value)}
                        >
                            <option value="">Select a Year</option>
                            {targetYearList.map((year, index) => (
                                <option key={index} value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    {(targetSettingMethod === 'Economic intensity' || targetSettingMethod === 'Physical intensity' || targetSettingMethod === 'Cement SDA 1.5ºC') && (
                        <div className="table-row">
                            <div className="row label">Base year output</div>
                            <input
                                type="number"
                                className="input grey"
                                value={baseYearOutput}
                                onChange={(e) => setBaseYearOutput(Number(e.target.value))}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                        </div>)}
                    {(targetSettingMethod === 'Physical intensity' || targetSettingMethod === 'Cement SDA 1.5ºC') &&
                        <div className="table-row">
                            <div className="row label">Target year output</div>
                            <input
                                type="number"
                                className="input grey"
                                value={targetYearOutput}
                                onChange={(e) => setTargetYearOutput(Number(e.target.value))}
                                inputMode="numeric"
                                pattern="[0-9]*"
                            />
                        </div>}
                </div>
            </div>

            <div className="section">
                {targetSettingMethod === 'Absolute Contraction Approach' &&
                    <h3>Cross-sector absolute reduction / Absolute contraction approach (ACA)</h3>}
                {targetSettingMethod === 'Economic intensity' &&
                    <h3>Economic intensity targets</h3>}
                {targetSettingMethod === 'Physical intensity' &&
                    <h3>Physical intensity targets</h3>}
                {targetSettingMethod === 'Cement SDA 1.5ºC' &&
                    <h3>Cement SDA 1.5ºC</h3>}

                <div className="data-table">
                    <div className="table-row table-header">
                        <div className="header-column"></div>
                        <div className="header-column">Base year ({baseYear})</div>
                        <div className="header-column">Target year ({targetYear})</div>
                        <div className="header-column">% SBT Reduction</div>
                    </div>

                    {targetSettingMethod === 'Absolute Contraction Approach' &&
                        <>
                            <div className="table-row">
                                <div className="row label">Absolute emissions - WB2C (tCO2e)</div>
                                <div className="input grey">{scope3Emission.toFixed(2)}</div>
                                <div
                                    className="input grey">{yearsList.length === 0 ? "NaN" : (scope3Emission * (1 - 0.025 * (yearsList.length - 1))).toFixed(2)}</div>
                                <div
                                    className="input grey">{yearsList.length === 0 ? "NaN" : (((yearsList.length - 1) * 0.025) * 100).toFixed(2)}%
                                </div>
                            </div>

                            <div className="table-row">
                                <div className="row label">Absolute emissions - 1.5C (tCO2e)</div>
                                <div className="input grey">{scope3Emission.toFixed(2)}</div>
                                <div
                                    className="input grey">{yearsList.length === 0 ? "NaN" : (scope3Emission * (1 - 0.042 * (yearsList.length - 1))).toFixed(2)}</div>
                                <div
                                    className="input grey">{yearsList.length === 0 ? "NaN" : (((yearsList.length - 1) * 0.042) * 100).toFixed(2)}%
                                </div>
                            </div>
                        </>}

                    {targetSettingMethod === 'Economic intensity' && <div className="table-row">
                        <div className="row label">Economic intensity (tCO2/unit value added)</div>
                        <div className="input grey">{economicIntensityBase.toFixed(2)}</div>
                        <div
                            className="input grey">{economicIntensityTarget.toFixed(2)}</div>
                        <div className="input grey">{(economicIntensityReduction * 100).toFixed(2)}%</div>
                    </div>}

                    {targetSettingMethod === 'Physical intensity' && <div className="table-row">
                        <div className="row label">Physical intensity (tCO2/custom physical unit)</div>
                        <div className="input grey">{economicIntensityBase.toFixed(2)}</div>
                        <div
                            className="input grey">{economicIntensityTarget.toFixed(2)}</div>
                        <div className="input grey">{(economicIntensityReduction * 100).toFixed(2)}%</div>
                    </div>}

                    {targetSettingMethod === 'Cement SDA 1.5ºC' && <div className="table-row">
                        <div className="row label">Physical intensity (tCO2e/t cement)</div>
                        <div className="input grey">{economicIntensityBase.toFixed(2)}</div>
                        <div
                            className="input grey">{cementTargetPhysicalIntensity.toFixed(2)}</div>
                        <div
                            className="input grey">{((economicIntensityBase - cementTargetPhysicalIntensity) / economicIntensityBase).toFixed(2)}%
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default Scope3ShortTermTool;